.NavBar {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0em 5%;
  border-bottom: 1px solid #cdcdcd;
}

.NavBarSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.NavBarSectionRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding: 0.2em 1em;
}

.NavBarSectionRight:hover {
  background: #f4f4f4;
}

.NavBarNotification {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding: 0.6em;
}

.NavBarNotification:hover {
  background: #f4f4f4;
}
