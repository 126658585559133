.MainScreenText {
  color: #555555;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  font-family: Open Sans;
  cursor: pointer;
}

.MainScreenText:hover {
  text-decoration: underline;
}

.DiscoverScreenText {
  color: #555555;
  font-weight: 400;
  font-size: 16px;
  font-family: Open Sans;
  /* margin-top: 1em; */
  cursor: pointer;
}

.DiscoverScreenText:hover {
  text-decoration: underline;
  /* font-weight: 700; */
  /* margin-left: 1em; */
}
