.SearchResultLine {
  /* margin-top: 0.3em; */
  padding: 0.5em 1em;
  cursor: "pointer";
}

.SearchResultLine:hover {
  background-color: #cdcdcd;
}

.SearchResultLineNoHover {
  margin-top: 0.3em;
  padding: 0.5em 1em;
}

.SearchFilterButton {
  background-color: #f4f4f4;
  padding: 0.5em;
  margin-right: 1em;
  border-radius: 6px;
  cursor: pointer;
}

.SearchFilterButton:hover {
  background-color: #E3F0D6;
}


.SearchFilterButtonSelected {
  background-color: #9dcb6e;
}

.SearchFilterButtonSelected:hover {
  background-color: #9dcb6e;
}
