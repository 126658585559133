.BodySortBlock {
  background: #fffffd;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  width: auto;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 2em;
  margin-block: 2em;
}

.BodySortButton {
  width: 6em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 1em;
  cursor: pointer;
}

.BodySortButtonSelected {
  background: #f4f4f4;
}

.BodySortButton:hover {
  background: #f4f4f4;
}

.AddPostButton {
  background: #f4f4f4;
  width: 8em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}

.AddPostButton:hover {
  background: #e4e4e4;
}

.BodyThreadItem {
  height: 6em;
  width: auto;
  background-color: #fefefe;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  display: flex;
  padding: 1em 0em;
  margin-bottom: 1em;
  cursor: pointer;
}

.BodyThreadItem:hover {
  margin: 0em -1em 1em -1em;
  padding: 1em 1em;
  background-color: #fbfbfb;
  /* border: 1px solid #555555; */
}
