.WorkspaceGridItem {
  background: #fefefe;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  padding: 2em 2em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.WorkspaceGridItem:hover {
  background-color: #fbfbfb;
  margin: 0em -1em;
  padding: 2em 3em;
}

.WorkspaceGridItemImage {
  height: 2em;
  width: 2em;
  margin-right: 1em;
}
