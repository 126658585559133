.OuterCommentBlock {
  border: 1px solid #cdcdcd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  padding: 1em 2em 1em 0em;
  margin-block: 2em;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}

.OuterCommentBlock:hover {
  /* padding: 2em 3em 2em 1em;
  margin: 2em -1em 2em -1em; */
  background-color: #fffffe;
}
